const tutorialJsonEn = {

    "welcome_to_simpla_tutorial": "Welcome to Simpla! To get you started, let’s set up your profile with just two quick details:",
    "select_conversation": "Select a conversation",
    "ask_queries": "This is where you can ask all your queries.",
    "click_submit":"Click here to submit", 

    signoff_icon_content: "Click the Sign-Off button to see how you can validate Simpla’s advice with a human expert.",
    feedback_title: "We'd love your feedback!",
    feedback_description: "Share your thoughts to help make Simpla even better.",
    desktop_profile_description: "Set up your profile, manage account settings, and view or update your subscription details, all in one place.",
    mobile_profile_description: "Set up your profile, manage account settings, and view or update your subscription details, all in one place.",
    view_sources_content: "Simpla has answered your query! Now, click the View Sources to see where this answer came from.",
    chat_sources_description: "This is where you can find the original references and documents behind Simpla’s responses.",
    chat_sources_prompt_plus: "Now click on the prompt plus icon",
    suggested_prompts_content1: "Prompt Plus also offers Suggested Prompts.",
    suggested_prompts_content2: "Click on any prompt below to explore a related conversation",
    suggested_prompts_icon_content: "Now click on the suggested prompt icon",
    action_prompts_title: "Welcome to Prompt Plus!",
    action_prompts_instruction: "Let's check the Action Prompts first.",
    action_prompt_email_instruction: "Select 'Write an email' from the list of available action prompts.",
    action_prompt_email_steps: "We have pre-selected 'Clarify'. This will help you craft an email with a clear message.",

    "great_prefilled_email_topic": "Great! For this demo, we’ve pre-filled the email topic for you.",
    "recipient_email_defaults": "For the recipient, we’ve set it to John Doe by default, and the email will be sent from your company name.",
    "personalise_email_tone": "Now, let’s personalise the email’s tone. Move the tone slider to the middle point between Friendly and Formal.",
    "generate_email_prompt": "Finally, click on the Generate Prompt button to create your email. You’ll see how Simpla builds a clear, well-crafted message based on your input!",
    "simpla_generated_email": "Here’s the email Simpla generated based on your input! It’s ready for use or export.",
    "manage_past_conversations": "Next, let’s explore how you can manage your past conversations using the History feature. Click the History icon from the menu to get started.",
    "previous_conversations_stored": "Here’s one of your conversations. In the History tab, all your previous interactions are stored neatly. You can always refer back to them whenever you need to.",
    "mark_favourite_conversation": "Now, let’s mark this conversation as a favourite! Click the 3 dots next to it and select 'Mark as favourite.' This helps you quickly find important conversations later.",
    "click_icon_mark_favourite": "You can click on this icon to mark the conversation as favourite!",
    "search_bar_locate_conversation": "See the search bar at the top? You can type keywords or topics here to instantly locate specific conversations.",
    "filter_marked_conversations": "This will allow you to filter all conversations that were marked as favourites.",
    "simpla_help_tour": "Let’s explore how Simpla supports you through its Help Tour feature. Start by clicking the Help Tour icon in the menu.",

    video_tutorial_description: "First, we have the Video Tutorials that helps you access quick, guided videos that walk you through essential features and how to use them.",
    faq_description: "Next, you’ll find the FAQs which answers common questions, helping you troubleshoot and understand key features at any time.",
    product_tour_description: "Lastly, is the Product Tour—the interactive walkthrough you’re currently using. You can access this to restart the tutorial anytime.",
    new_chat_explore: "Now Let’s explore how Simpla supports you through starting a new chat in any module. Start by clicking the New Chat icon in the menu.",
    advise_module_description: "Click here to start a new chat in our Advise module.",
    process_module_beta: "Our Process module is currently in beta mode but feel free to check it out.",
    analyse_module_description: "Same with our Analyse module -- continuously evolving. Give it a try!",
    tour_skipped_message: "Tour skipped! Click the Product Tour icon anytime to restart.",
    profile_incomplete_message: "Provide two quick details to get personalised tax advice.",
    click_close_message: "Click X to close.",

    "advise_sent": "Awesome! Your advice has been sent for validation.",
    "check_email_for_sign_off" : "Please check your email for the signed-off advice document."

};


const tutorialJsonAr = {

    "welcome_to_simpla_tutorial": "مرحبًا بك في Simpla! لبدء استخدامه، دعنا نقوم بإعداد ملفك الشخصي مع تفاصيل سريعة فقط:",
    "select_conversation": "اختر محادثة",
    "ask_queries": "هنا يمكنك طرح جميع استفساراتك.",
    "click_submit": "انقر هنا للإرسال",

    signoff_icon_content: "انقر على زر الإقرار لمعرفة كيفية التحقق من نصيحة Simpla مع خبير بشري.",
    feedback_title: "نحن نحب ملاحظاتك!",
    feedback_description: "شارك أفكارك للمساعدة في تحسين Simpla.",
    desktop_profile_description: "قم بإعداد ملفك الشخصي، وإدارة إعدادات الحساب، وعرض أو تحديث تفاصيل اشتراكك، كل ذلك في مكان واحد.",
    mobile_profile_description: "قم بإعداد ملفك الشخصي، وإدارة إعدادات الحساب، وعرض أو تحديث تفاصيل اشتراكك، كل ذلك في مكان واحد.",
    view_sources_content: "لقد أجاب Simpla على استفسارك! الآن، انقر على عرض المصادر لمعرفة مصدر هذا الجواب.",
    chat_sources_description: "هنا يمكنك العثور على المراجع الأصلية والمستندات التي تستند إليها ردود Simpla.",
    chat_sources_prompt_plus: "الآن انقر على أيقونة Prompt Plus",
    suggested_prompts_content1: "يقدم Prompt Plus أيضًا مقترحات.",
    suggested_prompts_content2: "انقر على أي اقتراح أدناه لاستكشاف محادثة ذات صلة.",
    suggested_prompts_icon_content: "الآن انقر على أيقونة الاقتراحات.",
    action_prompts_title: "مرحبًا بك في Prompt Plus!",
    action_prompts_instruction: "لنبدأ بمراجعة الإرشادات.",
    action_prompt_email_instruction: "اختر 'كتابة بريد إلكتروني' من قائمة الإرشادات المتاحة.",
    action_prompt_email_steps: "لقد اخترنا مسبقًا 'التوضيح'. سيساعدك هذا في صياغة رسالة بريد إلكتروني واضحة.",

    "great_prefilled_email_topic": "رائع! لقد قمنا بملء موضوع البريد الإلكتروني مسبقًا لك في هذا العرض التوضيحي.",
    "recipient_email_defaults": "بالنسبة للمستلم، قمنا بتعيينه إلى جون دو افتراضيًا، وسيتم إرسال البريد الإلكتروني من اسم شركتك.",
    "personalise_email_tone": "الآن، دعنا نخصص نغمة البريد الإلكتروني. حرك شريط التمرير إلى النقطة الوسطى بين ودي ورسمي.",
    "generate_email_prompt": "أخيرًا، انقر فوق زر إنشاء الإرشادات لإنشاء بريدك الإلكتروني. سترى كيف يبني سيمبلا رسالة واضحة ومصممة جيدًا بناءً على إدخالك!",
    "simpla_generated_email": "إليك البريد الإلكتروني الذي أنشأه سيمبلا بناءً على مدخلاتك! إنه جاهز للاستخدام أو التصدير.",
    "manage_past_conversations": "التالي، دعنا نستكشف كيف يمكنك إدارة محادثاتك السابقة باستخدام ميزة التاريخ. انقر على أيقونة السجل من القائمة للبدء.",
    "previous_conversations_stored": "إليك واحدة من محادثاتك. يتم تخزين جميع تفاعلاتك السابقة بدقة في علامة التبويب السجل. يمكنك دائمًا الرجوع إليها كلما احتجت.",
    "mark_favourite_conversation": "الآن، دعنا نحدد هذه المحادثة كمفضلة! انقر على النقاط الثلاث بجوارها وحدد 'وضع علامة كمفضلة.' هذا يساعدك على العثور بسرعة على المحادثات المهمة لاحقًا.",
    "click_icon_mark_favourite": "يمكنك النقر على هذه الأيقونة لوضع علامة على المحادثة كمفضلة!",
    "search_bar_locate_conversation": "راجع شريط البحث في الأعلى؟ يمكنك كتابة كلمات رئيسية أو مواضيع هنا لتحديد موقع المحادثات بسرعة.",
    "filter_marked_conversations": "سيمكنك ذلك من تصفية جميع المحادثات التي تم وضع علامة عليها كمفضلة.",
    "simpla_help_tour": "دعنا نستكشف كيف يدعم",

    video_tutorial_description: "أولاً، لدينا دروس الفيديو التي تساعدك في الوصول إلى مقاطع فيديو سريعة ومرشدة تُوضح الميزات الأساسية وكيفية استخدامها.",
    faq_description: "بعد ذلك، ستجد الأسئلة الشائعة التي تجيب عن الأسئلة الشائعة وتساعدك على حل المشكلات وفهم الميزات الرئيسية في أي وقت.",
    product_tour_description: "أخيرًا، جولة المنتج—التجول التفاعلي الذي تستخدمه حاليًا. يمكنك الوصول إلى هذا لإعادة تشغيل البرنامج التعليمي في أي وقت.",
    new_chat_explore: "دعونا نستكشف كيف يدعمك Simpla في بدء محادثة جديدة في أي وحدة. ابدأ بالنقر فوق رمز المحادثة الجديدة في القائمة.",
    advise_module_description: "انقر هنا لبدء محادثة جديدة في وحدة النصائح الخاصة بنا.",
    process_module_beta: "وحدة العملية الخاصة بنا حاليًا في وضع تجريبي، لكن لا تتردد في تجربتها.",
    analyse_module_description: "الأمر نفسه مع وحدة التحليل الخاصة بنا -- تتطور باستمرار. جربها!",
    tour_skipped_message: "تم تخطي الجولة! انقر على أيقونة جولة المنتج في أي وقت لإعادة التشغيل.",
    profile_incomplete_message: "قدم تفاصيل سريعة للحصول على نصيحة ضريبية مخصصة.",
    click_close_message: "انقر فوق X للإغلاق.",

    "advise_sent": "رائع! تم إرسال نصيحتك للتحقق.",
    "check_email_for_sign_off": "يرجى التحقق من بريدك الإلكتروني للحصول على مستند النصيحة الموقع.",

};


export function tutorialLocalizer(key) {

    // console.log("tutorial localizer call")

    if (localStorage.getItem("i18nextLng") === "ar"){
        if (tutorialJsonAr[key]){
            return tutorialJsonAr[key]   
        }
        else{
            return "..."
        }
        
    }
    else{
        if (tutorialJsonEn[key]){
            return tutorialJsonEn[key]   
        }
        else{
            return "..."
        }
    }

}